export default {
  setLoading(state, value) {
    state.loading = value;
  },
  setIsTakeAway(state, value) {
    state.isTakeAway = value;
  },
  setIsTakeAwayNow(state, value) {
    state.isTakeAwayNow = value;
  },
  setToggleModalMorada(state, value) {
    state.openModalMorada = value;
  },
  setToggleModalHour(state, value) {
    state.openModalHour = value;
  },
  setTakeAwayDateTime(state, value) {
    state.takeAwayDateTime = value;
  },
  setTakeAwaySchedule(state, value) {
    state.takeAwaySchedule = value;
  },
  setTakeAwayComment(state, value) {
    state.takeAwayComment = value;
  },
  setTakeAwayOrderNumber(state, value) {
    sessionStorage.setItem('takeAwayOrderNumber', value);
    state.takeAwayOrderNumber = value;
  },
  setTakeAwayBiipProcess(state, value) {
    sessionStorage.setItem('takeAwayBiipProcess', value);
    state.takeAwayBiipProcess = value;
  },
  setTakeAwayStatus(state, value) {
    sessionStorage.setItem('takeAwayStatus', value);
    state.takeAwayStatus = value;
  },
  setTakeAwayPickupTime(state, value) {
    sessionStorage.setItem('takeAwayPickupTime', value);
    state.takeAwayPickupTime = value;
  },

};