import store from '@/store';
import i18n from "@/i18n";
import router from '@/router'

export default class Geolocation {
    constructor() {
        this.store = store.getters['Site/storeConfig'];
        this.geolocation = store.getters['Site/geolocation'];
    }

    /**
     * carrega os dados de geolocation do utilizador     
     * @returns {Promise}
     */
    async loadGeolocation() {        
        return new Promise(function (resolve, reject) {
            window.$app.$getLocation({
                    timeout: Infinity,
                })
                .then((coordinates) => {
                    resolve(coordinates)                    
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    async requestLocation() {        
        await this.loadGeolocation().then(async (response) => {
            let geodata = this.geolocation;
            geodata.activeGeolocation = false;
            geodata.nearToBusiness = false;
            
            if (response) {
                this.geolocation = Object.assign(this.geolocation, response);
                let meters = await this.getDistanceMeters();
                let isOnRaioDistance = meters <= this.store.raioDistancia ? true : false;

                geodata = Object.assign(geodata, response);
                geodata.activeGeolocation = true;
                geodata.metersBetween = meters;
                geodata.nearToBusiness = isOnRaioDistance;
            }
            
            let notifyGeo = {};
            
            if(false === geodata.activeGeolocation){                
                notifyGeo = {message: i18n.t("errors.geo_denied")};
            }

            else if("lat" in geodata && false == geodata.nearToBusiness) {                
                notifyGeo = {message: i18n.t("errors.geo_far_away")};
            } 
            
            // qtd de idiomas disponíveis
			let listIdiomas = this.store.idiomas;            
			let qtdIdiomas = listIdiomas?.length;			

            // se houver apenas um idioma, as notificacoes de GEO devem ser lancadas para o idioma defaut
            if (Object.entries(notifyGeo).length && qtdIdiomas === 1) {                

                // define o idioma para os modais
                let lang = listIdiomas[0];
                lang = lang.id.toLowerCase();

                let newLang = await store.dispatch("Site/checkHasLangInLocales", lang);

                // define o idioma da aplicacao
                let langConfig = {
                    lang: lang,
                    newLang: newLang,
                    i18n
                };

                // salva o idioma no VUEX.
                await store.dispatch("Site/setLocale", langConfig, {
                    root: true
                });

                // define o texto dos modais
                store.dispatch("Modal/setAlert", notifyGeo, {
                    root: true
                });
            }

            store.dispatch("Site/setGeolocation", geodata, {
                root: true
            });
        });
    }

    /**
     * Calcula a distancia dos pontos da loja com o utilizador
     * @returns {Float} distancia em metros
     */
    async getDistanceMeters() {
        // business location
        let lat1 = this.store.latitude;
        let lon1 = this.store.longitude;
        let radBusiness = this.store.raioDistancia;

        // client device location
        let lat2 = this.geolocation.lat;
        let lon2 = this.geolocation.lng;
        
        if (lat1 == lat2 && lon1 == lon2) {
            return 0;
        } else {
            var radlat1 = (Math.PI * lat1) / 180;
            var radlat2 = (Math.PI * lat2) / 180;
            var theta = lon1 - lon2;
            var radtheta = (Math.PI * theta) / 180;
            var dist =
                Math.sin(radlat1) * Math.sin(radlat2) +
                Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = (dist * 180) / Math.PI;
            dist = dist * 60 * 1.1515;
            //miles to meters
            dist = dist / 0.00062137;            

            return dist;
        }
    }
}