// import { axiosInstance, axiosWithoutLoading, AxiosError } from "@/libs/axios";
import store from '@/store';
export default {
    setLoading(context, value) {
        context.commit('setLoading', value);
    },
    async setIsTakeAway(context) {
        context.commit('setIsTakeAway', await context.dispatch('isStoreOpenToTakeAway'))
    },
    setIsTakeAwayNow(context, value) {
        context.commit('setIsTakeAwayNow', value);
    },
    setToggleModalMorada(context, value) {
        context.dispatch('Site/toggleBackdrop', value, { root: true });
        context.commit('setToggleModalMorada', value)
    },
    setToggleModalHour(context, value) {
        context.dispatch('Site/toggleBackdrop', value, { root: true });
        context.commit('setToggleModalHour', value)
    },
    setTakeAwayDateTime(context, value) {
        context.commit('setTakeAwayDateTime', value)
    },
    setTakeAwayComment(context, value) {
        context.commit('setTakeAwayComment', value)
    },
    setTakeAwayOrderNumber(context, value) {
        context.commit('setTakeAwayOrderNumber', value)
    },
    setTakeAwayBiipProcess(context, value) {
        context.commit('setTakeAwayBiipProcess', value)
    },    
    setTakeAwayPickupTime(context, value) {
        context.commit('setTakeAwayPickupTime', value)
    },
    setTakeAwayStatus(context, value) {
        context.commit('setTakeAwayStatus', value)
    },    
    setTakeAwaySchedule(context, value) {
        context.commit('setTakeAwaySchedule', value)
    },
    /**
     *  Verifica: se a loja está aberta, se param de take away está aberto e se tem horário de take away
     *  Caso tudo for verdadeiro retorna true
     * @returns True if the store is open to take away
     */
    isStoreOpenToTakeAway() {

        //config apenas para a amostra dia 12
        let serial = store.getters['Site/storeSerial'];        

        let storeConfig = store.getters['Site/storeConfig'];

        if (!storeConfig) return false;
        if (serial.tableNumber != 0) return false;

        // verifica se a loja está aberta
        const isStoreOpen = storeConfig.storeOpen;
        // verifica se a loja possui TAKEAWAY activo
        const isTakeAwayEnabled = (true === storeConfig.pedidosTakeAway)

        // obtem a data atual. Validacao necessaria enquanto o takeaway for D+0
        const currentTzDate = new Date().toLocaleString('en-US', { timeZone: 'Europe/Lisbon'});        
        const currentWeekDay = new Date(currentTzDate).getDay() + 1;        

        // verifica se a loja possui agenda de horarios para o takaway
        const scheduleTime = storeConfig.takeAwaySchedule ?? [];
        const scheduleTimeHasHours = scheduleTime.length > 0;

        // verifica se a loja possui agenda de horarios takeaway para o dia atual.
        const scheduleTimeCurrentDay = scheduleTime.filter(( item ) => {return item.weekDay === currentWeekDay}); 
        const scheduleTimeAvailable = scheduleTimeCurrentDay.length > 0;       

        // a loja takeaway esta aberta e o restaurante a receber novos pedidos
        if (isStoreOpen && isTakeAwayEnabled) {            
            
            // se houver horarios definidos para os pedidos takeaway, o dia atual deve estar listado na agenda
            if (scheduleTimeHasHours) {                 
                // recupera o ultimo slot de horario configurado para o dia.
                const lastAvailableTime = scheduleTimeCurrentDay[scheduleTimeCurrentDay.length - 1];                

                // simulacao de uma data com a hora de fechamento
                let lastClosedTime = new Date("01/01/1970 " + lastAvailableTime.closeTime);              

                // simulacao de uma data com a hora atual
                let currentTime = new Date().toLocaleTimeString('pt-PT', { timeZone: 'Europe/Lisbon' }).slice(0, -3);
                currentTime = new Date("01/01/1970 " + currentTime);

                const currentEarlyClosedTime = lastClosedTime > currentTime;
                
                return scheduleTimeAvailable && currentEarlyClosedTime;
            }
            
            return true;            
        }

        return false;
    }
};