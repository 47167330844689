import {
    tablePaymentItems,
    onGatewayView,
    onPaymentView,
    onStatusView,
    isTakeAway,
    isPrePayment
} from '@/services/RouteMiddlewares';
const routes = [{
    path: '/payment',
    name: 'payment-route',
    component: () => import( /* webpackChunkName: "payment" */ '@/views/Payment'),
    beforeEnter: async (to, from, next) => {

        /** para pagamento antecipado, não direciona para a mesa */
        let flagTakeAway = await isTakeAway();
        let flagPrePayment = await isPrePayment();
        let is3dsPage = to.name == 'redirects';

        if (!flagTakeAway && !flagPrePayment && !is3dsPage) {
            tablePaymentItems(to, from, next)
        }        
        else { 
            next() 
        } 

    },
    children: [
        {
            path: '/',
            name: 'payment',
            component: () => import( /* webpackChunkName: "payment" */ '@/views/Payment/PaymentView'),
            beforeEnter: (to, from, next) => {
                onPaymentView(to, from, next)
            },
        },
        {
            path: 'mbway',
            name: 'mbway',
            component: () => import( /* webpackChunkName: "payment" */ '@/views/Payment/Mbway'),
            beforeEnter: (to, from, next) => {
                onGatewayView(to, from, next)
            },
        },
        {
            path: 'status',
            name: 'payment-status',
            component: () => import( /* webpackChunkName: "payment" */ '@/views/Payment/Status'),
            beforeEnter: (to, from, next) => {
                onStatusView(to, from, next)
            },
        },
        {
            path: 'redirects',
            name: 'redirects',
            component: () => import( /* webpackChunkName: "payment" */ '@/views/Payment/Redirects'),
            // beforeEnter: (to, from, next) => {
            //     onStatusView(to, from, next)
            // },
        },        
    ]
},]

export default routes;

// const routes = [
//     {
//         path: '/payment',
//         name: 'payment',
//         component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment'),
//         // children: children,
//     },
//     {
//         path: '/payment-mbway',
//         name: 'payment-mbway',
//         component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment/Mbway'),
//         // children: children,
//     },
//     {
//         path: '/payment-mastercard-or-visa',
//         name: 'payment-mastercard-or-visa',
//         component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment/MastercardOrVisa'),
//         // children: children,
//     },
//     {
//         path: '/payment-status',
//         name: 'payment-status',
//         component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment/Status'),
//         // children: children,
//     }
// ]

// export default routes;