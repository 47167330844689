import store from '@/store';
import Payments from './Payments';
import Store from "@/services/Store";

// returns true or false if is takeaway or not
export async function isTakeAway() {
    let result = await store.getters['TakeAway/isTakeAway'];
    return result;
}

// returns true or false if is prepayment or not
export async function isPrePayment() {
    let result = await store.getters['PrePayment/isPrePayment'];
    return result;
}

export async function checkStoreConfigs(to, from, next) {
    try {

        //pagina language é a home que inicia as variaveis ncessarias        
        if (['language', 'PageNotSerial', 'PageOffline', 'status-payment-cards'].indexOf(to.name) !== -1) {
            next();
            return;
        }
        let storeConfig = await store.getters['Site/storeConfig'];

        if (Object.keys(storeConfig).length == 0) {
            storeConfig = await store.dispatch('Site/loadStoreConfig');
        }

        next();
    } catch (e) {
        next({
            name: "language"
        })
    }
}

export async function tablePaymentItems(to, from, next) {
    try {        
        /* todos os timmers devem ser resetados */


        let nextPage = {};
        let tableData = await store.getters['Table/tableData'];

        let transactionData = await store.getters['Payment/transaction'];

        /** se nao tiver dados de transacao no VUEX, tenta recuperar do localStorage */
        // if (Object.keys(transactionData).length == 0) {                                   
        //     console.log('on tablePaymentItems');
        //     let trasactionDataFromStorage = JSON.parse(sessionStorage.getItem('x-transaction'));
        //     if (Object.keys(trasactionDataFromStorage).length != 0) {               
        //        await store.dispatch('Payment/setTransaction', trasactionDataFromStorage, { root: true });
        //        transactionData = trasactionDataFromStorage;
        //     }
        // }        

        if (Object.keys(tableData).length == 0 && to.name != 'payment-status') {
            await Payments.blockUnblockTablePayment(false);
            throw Error("Items empty")
        }

        if (to.name == 'payment-status') {  
            /** recovery vuex from localStorage */
            let trxFromStorage = JSON.parse(sessionStorage.getItem('x-transaction'));              
            if (Object.keys(transactionData).length == 0 && Object.keys(trxFromStorage).length != 0){
                store.dispatch('Payment/setTransaction', trxFromStorage, {
                    root: true
                });
                transactionData = trxFromStorage;                
            }                      
            
            if (Object.keys(transactionData).length == 0) {
                await Payments.blockUnblockTablePayment(false);                    
                nextPage = { name: 'menu' }
            }
            else {
                await store.dispatch('Payment/startCountdownPayment');
            }
        }

        if (from.name == 'mbway') {
            await Payments.blockUnblockTablePayment(false);
        }

        next(nextPage);
    } catch (e) {        
        next({
            name: "table"
        })
    }
}

export async function onPaymentView(to, from, next) {
    try {
        // tratativa para o caso de ser takeaway, e reutilização da flag para o resto do fluxo
        let flagTakeAway = await isTakeAway() || false;

        // -- POS PAGAMENTO        
        if ('mbway' == from.name) {
            if (!flagTakeAway) await Payments.blockUnblockTablePayment(false);
        }

        /* renova timmer de conclusao de tela */
        if (!flagTakeAway) await store.dispatch('Payment/startTimmerToConcludePayment', 90000, {
            root: false
        });
        // -- POS PAGAMENTO

        next();
    } catch (e) {
        next({
            name: "table"
        })
    }
}

export async function onStatusView(to, from, next) {
    try {      
        let transactionData = await store.getters['Payment/transaction'];
        let trasactionDataFromStorage = JSON.parse(sessionStorage.getItem('x-transaction'));

        /** o VUEX ja nao possui mais os dados da transacao. Buscamos do localStorage */
        if (!transactionData) {
            /** se a transacao estiver no localStorage, actualiza o VUEX */
            if (trasactionDataFromStorage) {                 
                await store.dispatch('Payment/setTransaction', trasactionDataFromStorage, { root: true });                
            }
        }
                
        next();
    } catch (e) {                   
        next({
            name: "menu"
        })
    }
}

export async function onGatewayView(to, from, next) {
    try {
        // tratativa para o caso de ser takeaway, e reutilização da flag para o resto do fluxo
        let flagTakeAway = await isTakeAway() || false;

        /* defined time to concluded */
        if (!flagTakeAway) {
            await Payments.blockUnblockTablePayment(true);
            await store.dispatch('Payment/startTimmerToConcludePayment', 90000, {
                root: false
            });
        }

        next();
    } catch (e) {
        next({
            name: "table"
        })
    }
}

export async function onBuildMenu(to, from, next) {
    try{
        let storeClass = new Store();  
        // verifica se apenas pagamentos estao sendo permitidos      
        if (true === storeClass.isPaymentOnly()){
            next({
                name: "table"
            }) 
        }
        else {
            loadMenuItems(to, from, next);
        }        
    } 
    catch (e) {
        next({
            name: "language"
        })
    }
}

export async function loadMenuItems(to, from, next) {
    try {


        /* todos os timmers devem ser resetados */        
        await store.dispatch('Payment/resetAllTimmers');
        let itemsMenu = await store.getters['Menu/menuItems'];

        if (Object.keys(itemsMenu).length == 0) {
            //a primeira vez carrega sem imagem mais rapido
            await store.dispatch('Menu/loadMenu');

            //deixa em segundo plano um novo request com as imagens
            // store.dispatch('Menu/loadMenuImages');
        }

        next();
    } catch (e) {
        next({
            name: "language"
        })
    }
}

export async function fetchTableItems(to, from, next) {
    try {
        /** pode ocorrer do utilizador actualizar a pagina pelo 'reload' do browser.
         * Nesse caso, o parametro -from- recebe NULL. A mesa , ainda sim, deve permanecer
         * desbloqueada.
         */
        let flagTakeAway = await isTakeAway() || false;

        const REFRESH_BROWSER = null;



        /* todos os timmers devem ser resetados */
        if (!flagTakeAway) {
            await store.dispatch('Payment/resetAllTimmers');            
        }

        // let pagesUnblockedTable = ['payment', 'menu', 'table', REFRESH_BROWSER];
        let pagesUnblockedTable = ['payment', REFRESH_BROWSER];

        let isUnblockCase = pagesUnblockedTable.indexOf(from.name) !== -1;
        if (true === isUnblockCase || !from.name) {
            /** 
             * desbloqueia a mesa antes de uma nova consulta de consumo.
             * Isso se faz necessario pois quando enviamos a propriedade BLOCK = FALSE
             * na consulta da mesa, o ZSREST tenta ler primeiro a mesa, e por ultimo
             * é que e feito o desbloqueio.
             */
            await Payments.blockUnblockTablePayment(false);
        }

        let params = isUnblockCase ? { block: false, freezee: true } : {};
        
        await store.dispatch('Table/loadTableItems', params);

        next();
    } catch (e) {
        next()
    }
}
