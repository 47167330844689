import Vue from 'vue'
import Vuex from 'vuex'
import Site from './Site';
import Modal from './Modal';
import Table from './Table';
import Menu from './Menu';
import Cart from './Cart';
import Freebee from './Freebee';
import Payment from './Payment';
import TakeAway from './TakeAway';
import PrePayment from './PrePayment';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Site,
    Modal,
    Table,
    Menu,
    Cart,    
    Freebee,    
    Payment,
    TakeAway,
    PrePayment
  }
})
