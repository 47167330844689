import _ from "lodash";

export default {
  setLoading(state, value){
    state.loading = value;
  },
  setTransaction(state, value){
    state.transaction = value;
    sessionStorage.setItem('x-transaction', JSON.stringify(value));  
  },
  setInvoicing(state, value){
    let props = _.cloneDeep(state.invoicing);
    state.invoicing = Object.assign(props, value);
  },
  clearInvoicing(state){    
    state.invoicing = {mobile_ddi: "351"};
  },
  setTotalToPay(state, value){
    state.totalToPay = value;
  },
  setIsActiveCommentRate(state, value){
    state.isActiveCommentRate = value
  },
  setOpenSplitAccount(state, value) {
    state.openSplitAccount = value;
  },
  setSplitData(state, value){
    state.splitData = value;
  },
  setGratification(state, value){
    state.gratification = value;
  },
  setClockInterval(state, value){
    state.clockInterval = value;
  },
  clearClockInterval(state){
    clearInterval(state.clockInterval);
    state.clockInterval = null;
  },
  setClockToConcludedPayment(state, value){
    state.clockToConcludedPayment = value;
  },
  clearClockToConcludedPayment(state){
    clearTimeout(state.clockToConcludedPayment);
    state.clockToConcludedPayment = null;
  },
  setTableBlockedInterval(state, value){
    state.clockTableBlockedInterval = value;
  },
  clearTableBlockedInterval(state){
    clearInterval(state.clockTableBlockedInterval);
    state.clockTableBlockedInterval = null;
  },
  setGooglePayConfigs(state, value) {
    state.googlePayData = value;
  },
  setGooglePayOrder(state, value) {
    state.googlePayOrder = value;
  },   
  setGoogleBtnEnable(state, value) {
    state.googleBtnEnable = value;
  }, 
  setApplePayConfigs(state, value) {
    state.applePayData = value;
  },
  setApplePayOrder(state, value) {
    state.applePayOrder = value;
  },
  setAppleBtnEnable(state, value) {
    state.appleBtnEnable = value;
  },
  setInvoicingWithNif(state, value) {
    state.invoicingWithNif = value;
  }
};