import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    /**
     * identifica o loading na pagina ull
     * @var boolean 
     */
    loading: false,
    /**
     * objeto que identifica {serial,terminal,table}
     * @var object
     */
    storeSerial: null,
    /**
     * objeto que contém as configurações
     * @var object {...}
     */
    storeConfig: {},
    /**
     * idioma da app
     * @var string
     */
    locale: null,
    /**
     * geolocation do utilizador
     * @var object
     */
    geolocation: {},
    /**
     * verifica se a loja precisa de ter a localizacao
     * ativa para conseguir solicitar itens e pagar
     * @var boolean
     */
    geolocationNeed: false,
    selectLangNeed: false,
    menuOpen: false,
    activeMenuFooter: false,
    activeFormFloating: false,
    isDesktop: null,
    socket: null,
  },
  actions,
  getters,
  mutations,
};