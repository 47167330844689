<template>
  <!-- style="--main-color: initial" -->
  <div id="app" @scroll="handleScroll()">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>

    <main class="content-main">
      <router-view />
    </main>

    <modal-alert :key="alert.key" v-if="alert.isVisible"></modal-alert>   
    <modal-drink></modal-drink> 
    <!--- BACKDROP -->
    <div class="backdrop_bar d-none"></div>
    <!-------------------->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters, mapActions } from "vuex";
import ModalAlert from "@/components/Modals/Alert";
import ModalDrink from "@/components/Modals/ModalTermsDrink";

export default {
  data() {
    return {
      menuProducts: null,
      menuLinkProducts: null,
      topMenuHeight: null,
      menuItems: null,
      scrollItems: [],
      elementOffsetTop: [],
      objectScroll: [],
      lastId: null,
      sumWidthMenu: null,
    }
  },
  components: {
    Loading,
    ModalAlert,    
    ModalDrink
  },
  computed: {
    ...mapGetters("Modal", ["alert"]),
    ...mapGetters("Site", ["isLoading", "storeConfig", "storeSerial"]),
    ...mapGetters("TakeAway", ["isTakeAway"]),
  },
  methods: {
    ...mapActions("Modal", ["setAlert"]),
    ...mapActions("TakeAway", ["setIsTakeAway"]),
    ...mapActions("PrePayment", ["setIsPrePayment"]),
    handleScroll(event) {
      this.setActiveHeaderWhenScroll(event);
    },
    getScrollItems() {
      //GET HEIGHT HEADER AND LINKS HEADER
      this.menuLinkProducts = document.getElementById("content-tabs-div--links");
      this.menuProducts = document.getElementById("content-tabs--menu");
      this.topMenuHeight = this.menuProducts.clientHeight + 50;
      this.menuItems = document.querySelectorAll(".content-tabs-menu--link");

      //GET ID HEADER
      this.scrollItems = Array.from(this.menuItems).map((element) => {
        let item = element.getAttribute("id");
        let width = element.offsetWidth;
        if (item.length) {
          return {
            id: item,
            width: width,
          };
        }
      });

      //GET OFFSET TOP SECTION
      this.elementOffsetTop = this.scrollItems.map((element) => {
        element = element.id.replace(/^menu-/, "");
        let section = document.querySelector(`#section-${element}`);
        
        if (element && section) {
          let item = this.getOffset(section).top;
          return item;
        }
      });

      //SET ID HEADER AND OFFSET TOP SECTION
      for (let i = 0; i < this.scrollItems.length; i++) {
        this.objectScroll[i] = {
          id: this.scrollItems[i].id,
          width: this.scrollItems[i].width,
          offset: this.elementOffsetTop[i],
        };
      }
    },
    setActiveHeaderWhenScroll(event) {
      // ativando e desativando a class scrolled no menu header
      this.toggleScrolledMenuHeader();

      // Get container scroll position
      var fromTop = event.currentTarget.scrollY + this.topMenuHeight;
      var cur = [];
      var curArray = [];

      // Get the object of current scroll item
      this.objectScroll.map((element) => {
        if (element && element.offset < fromTop) {
          cur.push(element);
          curArray.push(element);
        }
      });

      // Get the object of the current element
      cur = cur[cur.length - 1];
      var id = cur && Object.values(cur).length ? cur.id : null;

      if (this.lastId !== id) {
        this.lastId = id;
        // Set/remove active class
        this.menuItems.forEach((element) => {
          element.classList.remove("active");
        });
        if (id && document.querySelector(`#${id}`)) {
          document.querySelector(`#${id}`).classList.add("active");
        }

        let sumWidth = 0;
        curArray.forEach((element, index) => {
          if (index !== 0) {
            sumWidth += curArray[index - 1].width + 15;
          }
        });
        this.sumWidthMenu = sumWidth;
        this.menuLinkProducts.scroll({
          left: this.sumWidthMenu,
          top: 0,
          behavior: "smooth",
        });
      }
    },
    getOffset(element) {
      if (!element.getClientRects().length) {
          return { top: 0, left: 0 };
      }

      let rect = element.getBoundingClientRect();
      let win = element.ownerDocument.defaultView;
      return {
          top: rect.top + win.pageYOffset,
          left: rect.left + win.pageXOffset,
      };
    },
    toggleScrolledMenuHeader() {
      if (this.menuLinkProducts) {
        if (this.menuLinkProducts.querySelectorAll('.active').length > 0) {
          this.menuLinkProducts.classList.add('content-tabs-div--scrolled');
        } else {
          this.menuLinkProducts.classList.remove('content-tabs-div--scrolled');
        }
      }
    }
  },
  watch: {
    /*
     * Espera a chegada da configuração da loja para setar o isTakeAway
     */
    async storeConfig() {
      this.setIsPrePayment();
      await this.setIsTakeAway();
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$root.$on('TabsMenuUpdated', () => {
      this.getScrollItems();
    });
  },
};
</script>

<style lang="scss">
@import "./app.scss";
</style>
