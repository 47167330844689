import store from '@/store';

export default function money (value) {    
    if (isNaN(value)) {
        return value;
    }

    var num = value < 0.01 && value != 0 ? 3 : 2;

    var formatter = new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: num
    });

    return formatter.format(value).replace('€', store.getters['Site/storeConfig'].moeda);
}