import { axiosInstance, axiosWithoutLoading, AxiosError } from "@/libs/axios";

export default {
    setTableIsEmpty(context, value){
        context.dispatch('Site/toggleBackdrop', value, {root: true});
        context.commit('setTableIsEmpty', value)
    },

    setLoading(context, value){
        context.commit('setLoading', value);
    },

    async loadTableItems(context, params) {             
        context.commit('setLoading', true);
        let payload = Object.assign({
            /* lugar na mesa */
            seat: 0,

            /* true, se um novo pagamento estiver sendo iniciado */
            newpayment: false
        }, params);        

        return await axiosInstance({
            method: 'GET',
            url: 'table',
            params: payload,
        })       
        .then(response => response.data.data)
        .then(data => {            
            context.commit('setTableData', data);
            context.commit('setLoading', false);
            return data;
        });
    },

    async isTableClosed(context, params){             
        context.commit('setLoading', true);
        let payload = Object.assign({
            /* lugar na mesa */
            seat: 0,
            /** idioma do Biip escolhido pelo cliente */
            localeLang: 'PT'
        }, params);          

        return await axiosInstance({
            method: 'GET',
            url: 'table',
            params: payload,
        })       
        .then(response => response.data.data)
        .then(data => {            
            context.commit('setTableData', data);
            context.commit('setLoading', false);
            return data;
        });
    }
};