<template>
  <div>
    <b-modal id="modal-alert" centered class="modal-alert">
      <div class="modal-alert-itens">
        <div class="modal-alert-itens-text">
          <!-- <h2>{{ alert.type == "error" ? $t("swal.titles.error") : $t("swal.titles.warning") }}</h2> -->
          <p>{{ $t(alert.message) }}</p>
        </div>
        <div class="modal-alert-itens-btns">
          <button class="btn-black btn-close" @click.prevent="closeModal()">
            {{$t("swal.titles.close")}}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("Modal", ["alert"]),
  },
  methods: {
    ...mapActions("Modal", ["setAlert"]),
    showSwal() {
      this.$swal.fire({
        // icon: this.alert.type,
        // title: this.alert.type == "error" ? this.$t("swal.titles.error") : this.$t("swal.titles.warning"),
        text: this.$t(this.alert.message),
      });
    },
    closeModal() {
      this.$bvModal.hide("modal-alert");
      if ("afterUrl" in this.alert) {
        this.$router.push({
          name: this.alert.afterUrl,
        });
      }
    },
    //TODO: mixins for this.
    mappingErrorsCode(code) {
      const listingCodeMessages = {
        409: {
          message: this.$t("errors.tableIsUnderPayment"),
          type: "warning",
        },
      };

      if (listingCodeMessages[code]) {
        this.setAlert(Object.assign(this.alert, listingCodeMessages[code]));
      }
    },
  },
  mounted() {
    // checking error code
    if ("errorCode" in this.alert) {
      this.mappingErrorsCode(this.alert.errorCode);
    }

    if (!("showModal" in this.alert) || !this.alert.showModal) {
      this.showSwal();
    } else {
      this.$bvModal.show("modal-alert");
    }

    if ("url" in this.alert) {
      this.$router.push({
        name: this.alert.url,
      });
    }
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>