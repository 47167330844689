export default {    
    loading: state => state.loading,
    isTakeAway: state => state.isTakeAway,
    isTakeAwayNow: state => state.isTakeAwayNow,
    openModalMorada: state => state.openModalMorada,
    openModalHour: state => state.openModalHour,
    takeAwayDateTime: state => state.takeAwayDateTime,
    takeAwaySchedule: state => state.takeAwaySchedule,
    takeAwayComment: state => state.takeAwayComment,
    takeAwayOrderNumber: state => state.takeAwayOrderNumber || sessionStorage.getItem('takeAwayOrderNumber'),
    takeAwayBiipProcess: state => state.takeAwayBiipProcess || sessionStorage.getItem('takeAwayBiipProcess'),
    takeAwayPickupTime: state => state.takeAwayPickupTime || sessionStorage.getItem('takeAwayPickupTime'),
    takeAwayStatus: state => state.takeAwayStatus || sessionStorage.getItem('takeAwayStatus'),
}