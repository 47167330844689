import { axiosInstance } from "@/libs/axios";

export default {
    setIsActiveFreebee(context, value){
        context.dispatch('Site/toggleBackdrop', value, {root: true});
        context.commit('setIsActiveFreebee', value)
    },
    setIsActiveFreebeePromotion(context, value){
        context.dispatch('Site/toggleBackdrop', value, {root: true});
        if (value) {
            context.dispatch('listCampaigns');   
        }        
        context.commit('setIsActiveFreebeePromotion', value)
    },
    setFreebeeItems(context, value){
        context.commit('setFreebeeItems', value)
    },
    setFreebeeItemsAccepted(context, value){        
        let fbItems = context.getters?.freebeeItems;
        fbItems['data']['clientAccepted'] = value;
                
        context.commit("setFreebeeItems", fbItems);        
        context.dispatch('setIsActiveFreebee', false);        
    },    
    setMobile(context, value){
        context.commit('setMobile', value)
    },
    clearFreebeeData(context)
    {
        context.commit('setMobile', null);
    },
    async getLoyaltyDiscount(context, params) {           
        return await axiosInstance({
                method: 'POST',
                url: 'freebee/check-get-discount',
                data: Object.assign({
                    table_items: context.rootGetters['Table/tableData'],
                    split: context.rootGetters['Payment/splitData'],                    
                }, params)
            })
            .then(response => response.data.data)
            .then(data => {
                context.commit("setFreebeeItems", data);
                
                return data;
            })
    },
    async listCampaigns(context, params) {           
        return await axiosInstance({
                method: 'GET',
                url: 'freebee/list-campaigns',            
            }).then(response => response.data.data)
            .then(data => {
                context.commit("setCampaigns", data);
                
                return data;
            })
    }
};