import _ from "lodash";

export default {
  setActiveComment(state, value) {
    state.isActiveComment = value;
  },
  resetProductToAdd(state, value) {
    state.productToAdd = value;
    state.productSuggestions = {};
  },
  toogleButtonAddCart(state, value) {
    state.isActiveBtnAddItemCart = value;
  },
  setIsActiveViewCart(state, value) {
    state.isActiveViewCart = value;
  },
  setIsActiveBtnViewCart(state, value) {
    state.isActiveBtnViewCart = value;
  },
  setIsActiveSuggestions(state, value) {
    state.isActiveSuggestions = value;
  },
  deletePositionSuggestions(state, value) {
    let opts = _.cloneDeep(state.productSuggestions);
    delete opts[value];

    state.productSuggestions = opts;
  },
  resetSuggestions(state) {
    state.productSuggestions = {};
  },
  setProductSuggestions(state, value) {
    let opts = _.cloneDeep(state.productSuggestions);
    state.productSuggestions = Object.assign(opts, value);
  },
  setCart(state, value) {

    const items = value.items;
    //ordena os itens do carrinho pra colocar os que nao podem ser removidos por ultimo
    const sortedItems = Object.values(items).sort((a, b) => (a.can_remove === b.can_remove) ? 0 : a.can_remove ? -1 : 1);
    value.items = sortedItems;

    state.cart = value;
  },
  /**
   * esse mutation está criando um mobjeto dinamico
   * que servirá como trigger para exibir composicoes
   * por padrao ficam ocultos na pagina, porem caso selecione
   * esse campo sera preenchido com o codigo do produto que 
   * vai mostrar a lista de composicoes
   * @param {*} state 
   * @param {*} product 
   */
  setProductTriggers(state, product) {
    let data = {};
    //percorre todos os niveis
    if ('niveismenu' in product) {
      product.niveismenu.map((nivel) => {
        //verifica os produtos e adiciona a trigger
        if ('products' in nivel) {
          nivel.products.map((product) => {
            let nameTrigger = `nivel-${nivel.nivel}`;
            data[nameTrigger] = {
              fixedShowCompositions: nivel.nivel == 1
            };

            //verifica se tem complementares e se eles possuem opcoes
            //pois se possuir também teremos trigger para eles
            if ('complementares' in product) {
              product.complementares.map((complement) => {
                let nameTrigger = `nivelId:${nivel.nivel}-complementProdId:${product.codigo}-complementId:${complement.codigo}`;
                data[nameTrigger] = {
                  //alterado o fixed_qtd para obrigatorio
                  fixedShowCompositions: (parseInt(complement.obrigatorio) == 1)
                };
              })
            }
          })
        }
      })
    } else {
      //pois se possuir também teremos trigger para eles
      if ('complementares' in product) {
        product.complementares.map((complement) => {
          let nameTrigger = `complementProdId:${product.cod_produto}-complementId:${complement.codigo}`;
          data[nameTrigger] = {
            //alterado o fixed_qtd para obrigatorio
            fixedShowCompositions: (parseInt(complement.obrigatorio) == 1)
          };
        })
      }
    }
    state.productTrigger = data;
  },
  /**
   * adiciona no objeto productTrigger o produto
   * que o usuario selecionou, dessa maneira no frontend
   * consegue controlar e verificar se houve mudança para 
   * exibir ou nao as lista de composições
   * @param {*} state 
   * @param {*} param1 
   */
  setProductTrigger(state, {
    nameIndex,
    product
  }) {
    let trigger = _.cloneDeep(state.productTrigger);

    if (nameIndex in trigger) {
      trigger[nameIndex].product = product;
    }

    state.productTrigger = Object.assign(state.productTrigger, trigger);
  },
  setProductToAdd(state, payload) {
    let props = _.cloneDeep(state.productToAdd);
    props = Object.assign(props, payload);
    state.productToAdd = props;
  },
  /**
   * atualiza o valor total do carrinho sempre que 
   * houver interacoes no setProductAdd
   */
  async updatePriceTotalCart(state) {
    let productAdd = _.cloneDeep(state.productToAdd);
    let total = parseFloat(productAdd.precovenda)

    let $inputs = document.querySelectorAll('.field-item-cart');
    if ($inputs.length) {
      for (let index = 0; index < $inputs.length; index++) {
        const element = $inputs[index];
        //campo que tem quantidade
        if (element.getAttribute('type') == 'text') {          
          // total += parseFloat(element.getAttribute('data-price'));
          total += parseFloat(element.getAttribute('data-price')) * element.value;
        } else {
          if (element.checked && element.hasAttribute('data-price')) {            
            total += parseFloat(element.getAttribute('data-price'));
          }
        }
      }
    }
    
    state.productToAdd.priceTotal = total * productAdd.amount;
    // state.productToAdd.compositions = complementsUpdated;
  }
};