import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    /**
     * abre modal se a mesa estiver vazia
     * @var boolean
     */
    tableIsEmpty: false,
    tableData: {}
  },
  actions,
  getters,
  mutations,
};