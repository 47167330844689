import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    isActiveFreebee: false,
    isActiveFreebeePromotion: false,
    freebeeItems: {},
    mobile: null,
    campaigns: {}
  },
  actions,
  getters,
  mutations,
};