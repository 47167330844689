const routes = [
  {
    path: "/takeaway",
    name: "takeaway-route",
    component: () => import(/* webpackChunkName: "take-away" */ "@/views/TakeAway"),
    children: [
      {
        path: "status",
        name: "takeaway-status",
        beforeEnter: async (to, from, next) => {
          next();
        },
        component: () =>
          import(/* webpackChunkName: "take-away" */ "@/views/TakeAway/Status"),
      },
    ],
  },
];

export default routes;
